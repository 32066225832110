<template>
  <div class="table_wrapper">
    <!-- <div class="title"> -->
      <van-nav-bar
        title="碳行情（全国市场）"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
        fixed
      />
    <!-- </div> -->
    <el-table ref="multipleTable" :data="tableData" style="margin-top: 46px;">
      <!--  fontFamily: 'Arial Normal, Arial, sans-serif', -->
      <!-- 日期 -->
      <el-table-column
        prop="current_date"
        class="date"
        label="日期"
        align="left"
        width="80px"
        class-name="current_date"
        header-align="left"
      >
      </el-table-column>
      <el-table-column
        prop="end_price"
        label="最新价"
        width="48px"
        align="right"
        class-name="end_price"
        header-align="right"
      >
      </el-table-column>
      <!-- 涨跌 -->
      <el-table-column
        prop="percent"
        label="涨跌"
        width="55px"
        class-name="percent"
        header-align="right"
        align="right"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.percent.startsWith('-')" style="color: #07C160">
            {{ scope.row.percent }}
          </span>
          <span v-else-if="/^0.0+%$/.test(scope.row.percent)">
            {{ scope.row.percent }}
          </span>
          <span
            v-else="scope.row.percent.startsWith('+')"
            style="color: #ff2832"
          >
            {{ scope.row.percent }}
          </span>
        </template>
        <span> {{}} </span>
      </el-table-column>
      <!-- 成交量 -->
      <el-table-column
        prop="amount"
        label="成交量"
        width="58px"
        class-name="amount"
        header-align="right"
        align="right"
      >
      </el-table-column>
      <!-- 成交额 -->
      <el-table-column
        prop="total"
        class-name="total"
        label=" 成交额"
        width="90px"
        align="right"
        header-align="right"
      >
      </el-table-column>
      <!-- 振幅 -->
      <el-table-column
        prop="amplitude"
        class-name="amplitude"
        label=" 振幅"
        width="53px"
        header-align="right"
        align="right"
      >
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
    name: "TableWrapper",
    data() {
        return {
            pagination: {
                page: 1,
                limit: 20,
                page_count: 0,
            },
            tableData: [],
            tableData_Expand: [],
        };
    },
    created() {
        this.getTableData();
    },
    methods: {
        async getTableData(type) {
            return new Promise((resolve) => {
                // http://192.168.23.15:8000/article/carbonpricelist
                this.api
                    .postFormAPISM(
                        {
                            ...this.pagination,
                        },
                        "/article/carbonpricelist"
                    )
                    .then((res) => {
                        console.log("getTableData", res.data);
                        this.tableData = res.data.data.daily_price_list;
                        this.pagination.page_count = res.data.data.pagination.page_count;
                        resolve(this.tableData[0]);
                        // console.log(res.data.data.pagination.page_count,'res.data.data.pagination.page_count');
                        // console.log(
                        //   res.data.data.daily_price_list,
                        //   "res.data.data.daily_price_list"
                        // );
                    })
                    .catch((error) => {
                        console.log("error", error);
                    });
                // console.log(this.$store.state.oneNationalMarket,'kankanwo1');
                // this.tableData = this.$store.state.oneNationalMarket.daily_price_list;
                // this.pagination.page_count = this.$store.state.oneNationalMarket.pagination.page_count;
            });
        },
        onClickLeft() {
            this.$router.back();
        }
    },
};
</script>
<style lang="less">
.table_wrapper {
  // padding: 28px 0 0 0;
  & > .title {
    height: 26px;
    // margin: 0 0 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    font-family: "微软雅黑", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    color: #33353c;
    align-items: center;
    justify-content: center;
    &.expand {
      margin: 30px 0 10px;
    }
  }
   & > .el-table {
    padding-bottom: 46px;
    &::before {
      display: none;
    }
     & .el-table__header-wrapper
    {
       .el-table__header
      {
        width: 100% !important;
      }
    }
    & .el-table__body-wrapper{
       & .el-table__body{
        width: 100% !important;
       }
    }
    & .el-table__body tr:hover > td.el-table__cell {
      background-color: transparent;
    }

    &>.el-table {
        &::before {
            display: none;
        }

        & .el-table__body tr:hover>td.el-table__cell {
            background-color: transparent;
        }

        & th.el-table__cell {
            border-top: 1px solid #c5c5c5;
            border-bottom: 1px solid #c5c5c5;
            color: rgb(51, 51, 51);
            font-size: 12px;
            font-weight: 400;
            padding: 5px 0px;

            &>.cell {
                padding: 0 6px;
            }

            &.percent {
                &>.cell {
                    padding: 0 0 0 12px;
                }
            }

            &.amplitude {
                &>.cell {
                    padding: 0 6px 0 12px;
                }
            }
        }

        & td.el-table__cell {
            border-bottom: 1px solid #d7d7d7;
            color: rgb(51, 51, 51);
            font-size: 12px;
            font-weight: 400;
            padding: 5px 0px;

            &>.cell {
                // padding: 0;
                padding: 0 6px;
            }

            &.percent {
                &>.cell {
                    padding: 0 0 0 12px;
                }
            }

            &.amplitude {
                &>.cell {
                    padding: 0 6px 0 12px;
                }
            }
        }
    }
}
}
</style>
